<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import XMarkIcon from '@heroicons/vue/20/solid/XMarkIcon'
import ChevronDownIcon from '@heroicons/vue/20/solid/ChevronDownIcon'
import ChevronUpIcon from '@heroicons/vue/20/solid/ChevronUpIcon'
import { ref, computed } from 'vue'
import Loading from './Loading.vue'

import { ALL, type SelectOptionType } from '@/types'

const props = defineProps<{
  value?: string,
  id: string,
  options: SelectOptionType[],
  loading?: boolean,
  multiple?: boolean,
}>()

const emit = defineEmits(['input', 'input:query'])

const selected = ref<SelectOptionType | undefined>(props.options[0])
const query = ref('')

const filteredOptions = computed(() => query.value === ''
  ? props.options
  : props.options.filter((option) =>
    option.label
      .toLowerCase()
      .replace(/\s+/g, '')
      .includes(query.value.toLowerCase().replace(/\s+/g, '')),
  ))

const getQuery = (option: unknown): string => (option as SelectOptionType)?.label

const onQueryChange = (e: Event): void => {
  if (e === null || e.target === null) {
    return
  }

  if (!('value' in e.target)) {
    return
  }

  query.value = e.target.value as string
  emit('input:query', query.value)
}

const onQueryReset = (): void => {
  query.value = ''
}

watch(() => [props.value, props.options], () => {
  const _value = props.value || ALL

  const option = props.options.find((o: SelectOptionType) => o.label === _value || o.value === _value)
  if (option === undefined) {
    return
  }

  selected.value = option
}, { immediate: true })
</script>

<template>
  <div class="flex flex-col gap-2">
    <label :for="id" class="flex w-full pl-2 text-sm font-bold text-slate-600">
      <slot name="label" />
    </label>
    <Combobox v-slot="{ open }" v-model="selected" :multiple="multiple" @keyup.enter="onQueryChange">
      <div class="relative">
        <div
          class="focus-visible:outline-none focus-visible:ring-none relative w-full cursor-default overflow-hidden rounded-[30px] bg-slate-200 text-left">
          <ComboboxInput :id="id"
            class="text-sm text-slate-900 focus:ring-2 focus:ring-primary/20 outline-none h-10 w-full border-none bg-slate-200 px-4"
            :display-value="getQuery" @change="onQueryChange" />
          <ComboboxButton class="absolute inset-y-0 right-5 flex items-center px-4">
            <XMarkIcon v-if="query" class="h-5 w-5 text-slate-400" aria-hidden="true" @click="onQueryReset" />
          </ComboboxButton>
          <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-4">
            <ChevronUpIcon v-if="open" class="h-5 w-5 text-slate-400" aria-hidden="true" />
            <ChevronDownIcon v-else class="h-5 w-5 text-slate-400" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <TransitionRoot leave="transition ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0"
          @after-leave="onQueryReset">
          <ComboboxOptions static
            class="ring-1 ring-primary/50 absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
            <div v-if="loading" class="flex items-center justify-center py-4">
              <Loading />
            </div>
            <div v-else-if="filteredOptions.length === 0">
              <div v-if="query.length < 3"
                class="text-gray-700 text-center relative cursor-default select-none px-4 py-2">
                Digita almeno 3 caratteri per iniziare la ricerca.
              </div>
              <div v-else-if="query !== ''"
                class="text-gray-700 text-center relative cursor-default select-none px-4 py-2">
                Nessun risultato.
              </div>
            </div>
            <div v-else>
              <ComboboxOption v-for="option in filteredOptions" :key="option.id" v-slot="{ active: highlighted }"
                as="template" :value="option" @click="$emit('input', option.value)"
                @keyup.enter="$emit('input', option.value)">
                <li class="relative cursor-pointer select-none px-4 py-2" :class="{
                  'bg-primary text-white': selected?.value === option.value,
                  'bg-primary/80 text-white': highlighted,
                  'bg:primary/80 text-slate-400': !highlighted,
                }">
                  <span class="block line-clamp-3" :class="{
                    'font-medium': selected?.value === option.value,
                    'font-normal': selected?.value !== option.value,
                  }">
                    {{ option.label }}
                  </span>
                </li>
              </ComboboxOption>
            </div>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>
