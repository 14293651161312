<script setup lang="ts">
import type { SelectOptionType } from '@/types'

const props = defineProps<{
  value: string,
  id: string,
  options: SelectOptionType[],
}>()

defineEmits(['input'])

const isSelected = (idx: number): boolean =>
  props.options[idx].value === props.value

const getStyle = (idx: number): string => {
  const style: string[] = []

  if (props.options.length === 2) {
    style.push('w-1/2')
  } else if (props.options.length === 3) {
    style.push('w-1/3')
  } else if (props.options.length === 4) {
    style.push('w-1/4')
  } else if (props.options.length === 5) {
    style.push('w-1/5')
  }

  if (isSelected(idx)) {
    style.push('bg-primary text-white border-primary hover:bg-primary/85')
  } else {
    style.push('bg-white text-slate-900 hover:bg-slate-100 hover:text-primary')
  }

  if (idx === 0) {
    style.push('rounded-l-full')
  } else if (idx === props.options.length - 1) {
    style.push('rounded-r-full border-l-0')
  } else {
    style.push('rounded-none border-l-0')
  }

  return style.join(' ')
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <label :for="`${id}-1`" class="flex w-full pl-2 text-sm font-bold text-slate-600">
      <slot name="label" />
    </label>
    <div>
      <button v-for="(option, idx) in options" :key="idx" :id="`${id}-1`" v-tooltip.top="{
        value: option.label,
      }" type="button"
        class="relative inline-flex border-2 px-2 border-gray-200 focus:ring-none focus:z-20 h-10 items-center justify-center"
        :class="getStyle(idx)" @click="$emit('input', option.value)">
        <span class="w-full text-sm font-medium truncate text-ellipses">
          {{ option.label }}
        </span>
      </button>
    </div>
  </div>
</template>
