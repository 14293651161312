import type { SelectOptionType } from "~/types"

export const usePlace = () => {
  const getCity = async (value: string): Promise<SelectOptionType[]> => {
    const response = await fetch(
      `/api/places/comuni?nome=${value}`,
    )

    const parsedResponse = await response.json()
    return parsedResponse.map((d: any) => {
      return {
        id: d.nome,
        label: d.nome,
        value: d,
      }
    })
  }

  const getProvince = async (value: string): Promise<SelectOptionType[]> => {
    const response = await fetch(
      `/api/places/province?onlyname=true&nome=${value}`,
    )

    const parsedResponse = await response.json()
    return parsedResponse.map((d: any) => {
      return {
        id: d,
        label: d,
        value: d,
      }
    })
  }

  return {
    getCity,
    getProvince
  }
}