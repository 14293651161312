<script setup lang="ts">
import debounce from 'lodash/debounce'
import SelectField from './SelectField.vue';

import { ALL, type SelectOptionType } from '@/types'

const props = defineProps<{
  id: string,
  value?: string,
  query?: string,
}>()

const emit = defineEmits(['input'])

const { getCity } = usePlace()

const cityQuery = ref('')
const loading = ref(false)
const cityList = ref<SelectOptionType[]>([])

const onInput = (value: string) => {
  emit('input', value)
}

watch(() => props.query, (value) => {
  if (value === undefined) {
    return
  }
  cityQuery.value = value
}, { immediate: true })

if (process.client) {
  const updateCityList = debounce(async (value: string): Promise<void> => {
    loading.value = true
    cityList.value = [
      { id: ALL, label: 'Tutte', value: ALL },
      ...await getCity(value)
    ]
    loading.value = false
  }, 300)

  watch(
    () => cityQuery.value,
    (value) => {
      if (value.length > 2) {
        updateCityList(value)
      }
    },
    { immediate: true },
  )
}
</script>

<template>
  <SelectField :id="id" :value="value" :options="cityList" :loading="loading" @input="onInput"
    @input:query="cityQuery = $event">
    <template #label>
      <slot name="label">Città</slot>
    </template>
  </SelectField>
</template>
